import { createSlice } from '@reduxjs/toolkit';

import HiroApi from '../HiroApi';

const initialState = {
  campaigns: [],
  contactAttempts: [],
  contactAttemptsLoading: false,
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setContactAttempts: (state, action) => {
      state.contactAttempts = action.payload;
    },
    setContactAttemptsLoading: (state, action) => {
      state.contactAttemptsLoading = action.payload;
    },
  },
});

export const { setCampaigns, setContactAttempts, setContactAttemptsLoading } = campaignSlice.actions;

export const fetchContactAttempts = (campaignId, token) => async (dispatch) => {
  try {
    dispatch(setContactAttemptsLoading(true));

    const attempts = await HiroApi.getContactAttempts(campaignId, token);

    dispatch(setContactAttempts(attempts));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setContactAttemptsLoading(false));
  }
};

export default campaignSlice.reducer;

export const selectCampaigns = (state) => state.campaign.campaigns;
export const selectContactAttempts = (state) => state.campaign.contactAttempts;
export const selectContactAttemptsLoading = (state) => state.campaign.contactAttemptsLoading;
