import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { MESSAGE_TEMPLATES } from '../../utils/constants/message-templates';
import { CONSENT_STATUS, CONVERSATION_ACTOR, USER_ROLES } from '../../utils/constants/system';
import { mapConsentStatus } from '../../utils/helpers';
import { translate } from '../../utils/helpers-api';
import Message from './Message';

export default function MessageHistory(props) {
  const { campaign, getCampaignSilently, referrals, setCampaign } = props;
  const scrollRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const userType = useSelector(selectUserType);
  const [translateLoading, setTranslateLoading] = useState(false);
  const [getCampaignLoading, setGetCampaignLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [draftMessage, setDraftMessage] = useState('');
  const [debugMode, setDebugMode] = useState(false);
  const facility = useSelector(selectCurrentFacility);

  useEffect(() => {
    scrollToBottom();
  }, [campaign]);

  const getCampaign = async () => {
    setGetCampaignLoading(true);
    await getCampaignSilently();
    setGetCampaignLoading(false);
  };

  const sendMessage = async () => {
    try {
      setSending(true);
      const token = await getAccessTokenSilently();

      await HiroApi.sendMessageToCampaign(campaign.id, draftMessage, token);
      setDraftMessage('');
      await getCampaign(campaign.id);
      setSending(false);
    } catch (error) {
      console.log(error);
      setSending(false);
    }
  };

  const manualOverride = async () => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateCampaign(campaign.id, { autopilot: !campaign.autopilot }, token);
      setCampaign({ ...campaign, autopilot: !campaign.autopilot });
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToBottom = () => {
    const height = scrollRef.current.scrollHeight;

    scrollRef.current.scroll({ behavior: 'instant', top: height });
  };

  const isLastPatientMessage = (message, index) => {
    if (message.actor === CONVERSATION_ACTOR.PATIENT && index === campaign.messages.length - 1) {
      return true;
    }

    return false;
  };

  const translateMessage = async (message) => {
    setTranslateLoading(true);

    const token = await getAccessTokenSilently();
    const translation = await translate({ message }, token);

    setDraftMessage(`${draftMessage}\n\n${translation.body}`);
    setTranslateLoading(false);
  };

  const handleCampaignNumberChange = async (event) => {
    try {
      const token = await getAccessTokenSilently();
      const newNumber = event.target.value.slice(2);

      await HiroApi.updateCampaign(campaign.id, { phone: newNumber }, token);
      setCampaign({ ...campaign, phone: newNumber });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleConsent = async () => {
    try {
      const token = await getAccessTokenSilently();

      let consent;
      if (campaign.consent === CONSENT_STATUS.IN) {
        consent = CONSENT_STATUS.OUT;
        await HiroApi.optOutSMS(campaign.id, token);
      } else if (campaign.consent === CONSENT_STATUS.OUT) {
        consent = CONSENT_STATUS.IN;
        await HiroApi.optIn(campaign.id, token);
      } else {
        return;
      }
      setCampaign({ ...campaign, consent });
      await getCampaign(campaign.id);
    } catch (error) {
      console.log(error);
    }
  };

  const mapConsentStatusClickable = (consent) => {
    switch (consent) {
      case CONSENT_STATUS.IN:
        return (
          <IconButton onClick={toggleConsent}>
            <CheckCircleOutlinedIcon className="green status" />
          </IconButton>
        );
      case CONSENT_STATUS.OUT:
        return (
          <IconButton onClick={toggleConsent}>
            <CancelOutlinedIcon className="red status" />
          </IconButton>
        );
      case CONSENT_STATUS.PENDING:
        return <PendingOutlinedIcon className="gray status" />;
      default:
        return '';
    }
  };

  const isOptedOut = (phone) => {
    if (phone.optOut) {
      return true;
    }

    return false;
  };

  const isWrongNumber = (phone) => {
    if (phone.wrongNumber) {
      return true;
    }

    return false;
  };

  const isCampaignNumberOptedOut = () => {
    const campaignNumber = campaign.phoneNumbers.find((phone) => phone.phoneNumber === `+1${campaign.phone}`);

    if (!isEmpty(campaignNumber) && (campaignNumber.optOut || campaign.wrongNumber)) {
      return true;
    }

    return false;
  };

  return (
    <div className="message-history section">
      <div className="header">
        <h3>Message History</h3>

        <div className="header-options">
          <FormControl size="small">
            <InputLabel>Campaign Number</InputLabel>
            <Select
              label="Campaign Number"
              className="campaign-number"
              value={`+1${campaign.phone}`}
              onChange={handleCampaignNumberChange}
              disabled={userType !== USER_ROLES.ADMIN}
            >
              {campaign.phoneNumbers.map((phone) => (
                <MenuItem
                  key={phone.phoneNumber}
                  className={isOptedOut(phone) ? 'red' : isWrongNumber(phone) ? 'orange' : null}
                  value={phone.phoneNumber}
                  disabled={isOptedOut(phone) || isWrongNumber(phone)}
                >
                  {phone.nationalFormat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {userType === USER_ROLES.ADMIN
            ? mapConsentStatusClickable(campaign.consent)
            : mapConsentStatus(campaign.consent)}

          {userType === USER_ROLES.ADMIN ? (
            <FormControlLabel
              control={<Switch checked={debugMode} onChange={() => setDebugMode(!debugMode)} />}
              label="Debug Mode"
              labelPlacement="start"
            />
          ) : null}
          {getCampaignLoading ? (
            <Loader />
          ) : (
            <IconButton onClick={getCampaign}>
              <RefreshIcon />
            </IconButton>
          )}
        </div>
      </div>

      <div className="messages" ref={scrollRef}>
        {campaign.messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            debugMode={debugMode}
            campaign={campaign}
            isLastPatientMessage={isLastPatientMessage(message, index)}
            referrals={referrals}
            getCampaign={getCampaign}
          />
        ))}
      </div>

      {userType === USER_ROLES.ADMIN && campaign.consent !== CONSENT_STATUS.OUT && !isCampaignNumberOptedOut() ? (
        <div className="actions">
          <div className="input">
            <TextField
              multiline
              rows={5}
              value={draftMessage}
              onChange={(event) => setDraftMessage(event.target.value)}
            />
          </div>
          {campaign.autopilot ? (
            <div className="buttons">
              <Button variant="outlined" className="override" onClick={manualOverride}>
                Manual Override
              </Button>
            </div>
          ) : (
            <div className="buttons">
              {sending ? (
                <Loader />
              ) : (
                <Button variant="contained" className="manual" onClick={sendMessage}>
                  Send
                </Button>
              )}
              <Button variant="contained" className="manual" onClick={manualOverride}>
                Autopilot
              </Button>
            </div>
          )}
        </div>
      ) : null}

      {userType === USER_ROLES.ADMIN && campaign.consent !== CONSENT_STATUS.OUT && !isCampaignNumberOptedOut() ? (
        <div className="templates">
          <Chip className="toggle" label={showTemplates ? '-' : '+'} onClick={() => setShowTemplates(!showTemplates)} />

          {showTemplates
            ? MESSAGE_TEMPLATES(facility).map((template) => (
                <Chip
                  variant="outlined"
                  key={template.label}
                  label={template.label}
                  onClick={() => setDraftMessage(template.message)}
                />
              ))
            : null}

          <div className="translate">
            <Chip variant="outlined" label="Translate" onClick={() => translateMessage(draftMessage)} />
            {translateLoading ? <Loader size={25} /> : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
