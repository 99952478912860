import './Loader.scss';

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function Loader({ size = 40, centered = false }) {
  return (
    <div className={centered ? 'loader centered' : 'loader'} title="Loading">
      <CircularProgress size={size} />
    </div>
  );
}
