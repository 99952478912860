import './style.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';

export default function PatientWizard({ isOpen }) {
  const [expanded, setExpanded] = useState('');

  const acceptedPayors = [
    'Aetna',
    'Alliance Health Plan',
    'Ambetter / Centene - NC',
    'Amerihealth Caritas - Medicaid',
    'Blue Medicare',
    'Carolina Complete Health',
    'Cigna',
    'Friday Health Plans',
    'Healthy Blue',
    'Humana',
    'Medcost Benefit Services',
    'NC BCBS',
    'NC Medicaid',
    'NC Medicare',
    'THE HEALTH PLAN DIRECT NET',
    'Tricare Standard',
    'United Healthcare',
    'United Healthcare Community Plan',
    'Veteran Affairs (Thru Optum)',
    'Wellcare',
    'WellCare Medicaid',
  ];

  const specialCases = ['MRA Auto Liability', `Workers' Comp`, 'VA Fee Basis/Veterans Administration'];

  const handleChange = (panel) => {
    if (panel === expanded) {
      setExpanded('');
    } else {
      setExpanded(panel);
    }
  };

  return isOpen ? (
    <Paper className="patient-wizard" elevation={3}>
      <Accordion expanded={expanded === 'panel1'} onChange={() => handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Scheduling Restrictions</AccordionSummary>
        <AccordionDetails className="restrictions">
          <h4>FC Claremont Family Medicine</h4>
          <h5>Hawkins, Mark</h5>
          <p className="bold">Do NOT schedule at following times:</p>
          <p>Mon, Fri @ 11:30am or 4:30pm</p>
          <p>Tue, Wed, Thu @ 12:30pm</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={() => handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Accepting New Patients</AccordionSummary>
        <AccordionDetails className="new-patient-acceptance">
          <div className="provider">
            <h5>Simhachalam, Michael (MD)</h5>
            <p>FC Generations Family</p>
            <p>2810 16th St NE</p>
            <p>Hickory, NC 28601</p>
            <p>828-324-0359</p>
          </div>
          <div className="provider">
            <h5>Attaway, Cynthia (FNP-C)</h5>
            <h5>Woodham, Billie (FNP-C)</h5>
            <p>FC Hickory Family</p>
            <p>210 13th Ave. Pl NW, Suite 120</p>
            <p>Hickory, NC 28601</p>
            <p>828-328-2941</p>
          </div>
          <div className="provider">
            <h5>Gullett, Jesse (FNP-C)</h5>
            <h5>Shore, Hunter (PA)</h5>
            <p>FC Family Physicians-Lenoir</p>
            <p>1041 Morganton Blvd SW Suite 100</p>
            <p>Lenoir, NC 28645</p>
            <p>828-304-2527</p>
          </div>
          <div className="provider">
            <h5>Duncan, Quiana (NP)</h5>
            <p>FC Lincolnton Medical Group</p>
            <p>1470 East Gaston St Suite 300</p>
            <p>Lincolnton, NC 28092</p>
            <p>704-735-7474</p>
          </div>
          <div className="provider">
            <h5>Hawkins, Mark (MD)</h5>
            <p>FC Claremont Family Medicine</p>
            <p>3221 W Main St.</p>
            <p>Claremont, NC 28610</p>
            <p>828-459-4445</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={() => handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Secondary Bookings / Affiliate Process</AccordionSummary>
        <AccordionDetails className="affiliates">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Primary Provider</TableCell>
                <TableCell>Secondary Provider(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <p className="bold">Fisher, Michael (MD)</p>
                  <p>FC Hickory Family</p>
                </TableCell>
                <TableCell>
                  <p>Attaway, Cynthia (FNP-C)</p>
                  <p>Woodham, Billie (FNP-C)</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p className="bold">Du Sablon, Amanda (DO)</p>
                  <p>FC Family Physicians-Lenoir</p>
                </TableCell>
                <TableCell>
                  <p>Gullett, Jesse (FNP-C)</p>
                  <p>Shore, Hunter (PA)</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p className="bold">Smith, Ailisa (MD)</p>
                  <p>FC Generations Family</p>
                </TableCell>
                <TableCell>Simhachalam, Michael (MD)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p className="bold">Nelson, Catherine (PA)</p>
                  <p>FC Generations Family</p>
                </TableCell>
                <TableCell>Simhachalam, Michael (MD)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p className="bold">Britton, Mary (MD)</p>
                  <p>FC Family Physicians</p>
                </TableCell>
                <TableCell>
                  <p>Vang, Mai (PA)</p>
                  <p>Monte-Parker, Anne (FNP-C)</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={() => handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Accepted Payors</AccordionSummary>
        <AccordionDetails className="accepted-payors">
          <List dense>
            {acceptedPayors.map((payor) => (
              <ListItem key={payor}>{payor}</ListItem>
            ))}
          </List>

          <h4>Special Cases:</h4>
          <List dense>
            {specialCases.map((payor) => (
              <ListItem key={payor}>{payor}</ListItem>
            ))}
          </List>
          <p>For the above payor sources, when booking an unassigned patient:</p>
          <p>
            1. Book at least <b>1 week out</b>
          </p>
          <p>2. Highlight the patient in Kouper app</p>
          <p>
            3. For <b>Auto Liability</b> and <b>Workers&apos; Comp</b> cases, ask for any claim numbers and contact
            information for the cases, and include them in the notes when booking the patient
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={() => handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Self-Pay Rates</AccordionSummary>
        <AccordionDetails className="self-pay-costs">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Visit Type</TableCell>
                <TableCell align="center">Prompt Pay Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <p className="bold green">New Patient</p>
                  <p>15-29 minutes</p>
                </TableCell>
                <TableCell align="center" className="bold">
                  $140.80
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <p className="bold orange">Established</p>
                  <p>10-19 minutes</p>
                </TableCell>
                <TableCell align="center" className="bold">
                  $82.40
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={() => handleChange('panel6')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Important Notes</AccordionSummary>
        <AccordionDetails className="important-notes">
          <div className="note">
            <h5>Note 1:</h5>
            <p>
              <span>Anne Monte-Parker</span> is in 2 departments. Be sure to book in the correct department:
            </p>
            <p className="subtext">1. FC Family Physicians</p>
            <p className="subtext">2. FC Generations Family</p>
          </div>
          <div className="note">
            <h5>Note 2:</h5>
            <p>
              <b>FC Adult and Family Medicine - Taylorsville</b> is not part of FryeCare, as of 5/31/24. Any patients
              for <span>Sandra Reed</span> can be booked as <b>NEW PATIENTS</b> with the following providers depending
              on patient&apos;s preferred location:
            </p>
            <p className="subtext">
              <b>1. Hickory:</b>
            </p>
            <p className="subtext indent">
              <b>Simhachalam, Michael (MD)</b> at FC Generations Family
            </p>
            <p className="subtext indent">
              <b>Attaway, Cynthia (FNP-C)</b> at FC Hickory Family
            </p>
            <p className="subtext indent">
              <b>Woodham, Billie (FNP-C)</b> at FC Hickory Family
            </p>
            <p className="subtext">
              <b>2. Lenoir:</b>
            </p>
            <p className="subtext indent">
              <b>Gullett, Jesse (FNP-C)</b> at FC Family Physicians-Lenoir
            </p>
            <p className="subtext indent">
              <b>Shore, Hunter (PA)</b> at FC Family Physicians-Lenoir
            </p>
          </div>
          <div className="note">
            <h5>Note 3:</h5>
            <p>Always verify patient information before completing booking:</p>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Assigned</TableCell>
                  <TableCell align="left">Unassigned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <p>1. Last Name</p>
                    <p>2. First Name</p>
                    <p>3. Date of birth</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>1. Last Name</p>
                    <p>2. First Name</p>
                    <p>3. Date of birth</p>
                    <p>4. Insurance policy name</p>
                    <p>5. Insurance policy number</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  ) : null;
}
