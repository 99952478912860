import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { find, findIndex, isEmpty, reject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import LoadingButton from '../../components/LoadingButton/component';
import HiroApi from '../../HiroApi';
import { selectProviders } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import { copyToClipboard, formatDate, formatTime, getDepartmentInfo } from '../../utils/helpers';
import { fetchDepartments } from '../../utils/helpers-api';
import AppointmentTypeFilter from './AppointmentTypeFilter';
import CancellationModal from './CancellationModal';
import ConfirmationModal from './ConfirmationModal';
import DepartmentFilter from './DepartmentFilter';
import ProviderFilter from './ProviderFilter';
import SpecialtyFilter from './SpecialtyFilter';

export default function Appointments(props) {
  const { patient, campaignId, getCampaign, referrals } = props;
  const { getAccessTokenSilently } = useAuth0();
  const providers = useSelector(selectProviders);
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(true);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [appointmentSlotsLoading, setAppointmentSlotsLoading] = useState(false);
  const [showNewApptOptions, setShowNewApptOptions] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [appointmentToCancel, setAppointmentToCancel] = useState({});
  const [specialtyId, setSpecialtyId] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [providerFilter, setProviderFilter] = useState([]);
  const [providersBySpecialtyAndDept, setProvidersBySpecialtyAndDept] = useState([]);
  const [apptType, setApptType] = useState('new');
  const [startDate, setStartDate] = useState(dayjs().format('MM/DD/YYYY'));
  const [appointmentSummary, setAppointmentSummary] = useState('');
  const [appointmentSummaryLoading, setAppointmentSummaryLoading] = useState(false);
  const [appointmentReminder, setAppointmentReminder] = useState('');
  const [appointmentReminderLoading, setAppointmentReminderLoading] = useState(false);

  useEffect(() => {
    if (isEmpty(appointments) && !showNewApptOptions) {
      getAppointments();
    }

    if (showNewApptOptions && !isEmpty(specialtyId) && !isEmpty(departmentFilter) && !isEmpty(providerFilter)) {
      getAppointmentSlots();
    }
  }, [specialtyId, departmentFilter, providerFilter, apptType, startDate]);

  const getProviders = async (specialty, departments) => {
    try {
      const token = await getAccessTokenSilently();
      const providers = await HiroApi.getProviders(specialty, departments, token);

      setProvidersBySpecialtyAndDept(providers);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async (specialtyId) => {
    const token = await getAccessTokenSilently();

    fetchDepartments(specialtyId, token);
  };

  const getAppointments = async () => {
    try {
      const token = await getAccessTokenSilently();
      let appointments;

      if (campaignId) {
        appointments = await HiroApi.getCampaignAppointments(campaignId, token);
      } else {
        appointments = await HiroApi.getPatientAppointments(patient.id, token);
      }

      setAppointments(appointments);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const bookAppointment = async (slot, notes) => {
    try {
      const token = await getAccessTokenSilently();
      const payload = {
        ehrId: slot.appointmentId,
        appointmentType: apptType,
        providerId: slot.providerId,
        referralId: slot.referralId,
        departmentId: slot.departmentId,
        campaignId,
        notes,
      };

      await HiroApi.bookAppointment(payload, token);
      setConfirmationModalOpen(false);
      setShowNewApptOptions(false);
      await getCampaign();
    } catch (error) {
      console.log(error);
    } finally {
      setBookingLoading(false);
    }
  };

  const cancelAppointment = async (appointmentId, reasonId, notes) => {
    setCancelLoading(appointmentId);

    try {
      const token = await getAccessTokenSilently();

      await HiroApi.cancelPatientAppointment(appointmentId, { reasonId, notes }, token);
      await getCampaign();
      await getAppointments();
    } catch (error) {
      console.log(error);
    } finally {
      setCancelLoading('');
    }
  };

  const getAppointmentSlots = async () => {
    try {
      setAppointmentSlotsLoading(true);

      const token = await getAccessTokenSilently();
      const slots = await HiroApi.getAppointmentSlots(providerFilter, departmentFilter, apptType, startDate, token);

      setAppointmentSlots(slots);
      setAppointmentSlotsLoading(false);
    } catch (error) {
      console.log(error);
      setAppointmentSlotsLoading(false);
    }
  };

  const getAppointmentSummary = async () => {
    setAppointmentSummaryLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const summary = await HiroApi.getAppointmentSummary(campaignId, token);

      setAppointmentSummary(summary);
      setAppointmentReminder('');
    } catch (error) {
      console.error(error);
    } finally {
      setAppointmentSummaryLoading(false);
    }
  };

  const getAppointmentReminder = async () => {
    setAppointmentReminderLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const summary = await HiroApi.getAppointmentReminder(campaignId, token);

      setAppointmentReminder(summary);
      setAppointmentSummary('');
    } catch (error) {
      console.error(error);
    } finally {
      setAppointmentReminderLoading(false);
    }
  };

  const refresh = async () => {
    setIsRefreshing(true);

    try {
      await getAppointments();
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const selectProvider = (selectedProvider) => {
    let currentProviders = providerFilter.slice();

    if (findIndex(currentProviders, ['id', selectedProvider.id]) > -1) {
      currentProviders = reject(currentProviders, { id: selectedProvider.id });
    } else {
      currentProviders.push(selectedProvider);
    }

    setProviderFilter(currentProviders);
  };

  const selectDepartment = (selectedDepartment) => {
    let currentDepartments = departmentFilter.slice();

    if (findIndex(currentDepartments, (dept) => dept === selectedDepartment.id) > -1) {
      currentDepartments = currentDepartments.filter((dept) => dept !== selectedDepartment.id);
      resetProviderFilter();
    } else {
      currentDepartments.push(selectedDepartment.id);
    }

    setDepartmentFilter(currentDepartments);
    getProviders(specialtyId, currentDepartments);
  };

  const resetProviderFilter = () => {
    setProviderFilter([]);
    setAppointmentSlots([]);
  };

  const getProviderName = (providerId) => {
    const provider = find(providers, ['id', providerId]);

    if (isEmpty(provider)) {
      return '';
    }
    return provider.displayName;
  };

  const handleModalOpen = (event) => {
    const selectedSlot = find(appointmentSlots, ['appointmentId', event.target.id]);

    setSelectedSlot(selectedSlot);
    setConfirmationModalOpen(true);
  };

  const handleModalSuccess = async (slot, notes) => {
    setBookingLoading(true);
    await bookAppointment(slot, notes);
  };

  const handleModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const copyDepartmentInfo = (departmentId) => {
    const department = getDepartmentInfo(departmentId);
    const { address, city, name, state, zip } = department;
    const text = `${name}\n${address}, ${city}, ${state} ${zip}`;

    copyToClipboard(text);
  };

  const handleCancelModalOpen = (appointment) => {
    setAppointmentToCancel(appointment);
    setCancelConfirmationOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelConfirmationOpen(false);
    setAppointmentToCancel({});
  };

  const handleCancelModalSuccess = (appointmentId, reason, notes) => {
    cancelAppointment(appointmentId, reason, notes);
    setCancelConfirmationOpen(false);
    setAppointmentToCancel({});
  };

  const disableSummaryandReminder = () => {
    if (appointments.length > 0 && userType === USER_ROLES.ADMIN) {
      return false;
    }

    return true;
  };

  const appointmentStatusIcon = (status, statusVerbose) => {
    let icon;

    if (status === 'cancelled') {
      icon = <EventBusyOutlinedIcon />;
    } else {
      icon = <EventAvailableOutlinedIcon />;
    }

    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              fontSize: '14px',
            }}
          >
            {statusVerbose}
          </div>
        }
        placement="bottom"
        arrow
      >
        {icon}
      </Tooltip>
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="appointments section">
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleModalClose}
        onSuccess={handleModalSuccess}
        slot={selectedSlot}
        patient={patient}
        referrals={referrals}
        loading={bookingLoading}
        dobConfirmationRequired
      />

      <CancellationModal
        open={cancelConfirmationOpen}
        onClose={handleCancelModalClose}
        onSuccess={handleCancelModalSuccess}
        appointment={appointmentToCancel}
      />

      <div className="header">
        <h3>Kouper Appointments</h3>

        <LoadingButton
          icon={<AutoAwesomeOutlinedIcon />}
          id="summarize"
          isLoading={appointmentSummaryLoading}
          label="Summarize"
          onClick={getAppointmentSummary}
          variant="outlined"
          disabled={disableSummaryandReminder()}
        />

        <LoadingButton
          icon={<AutoAwesomeOutlinedIcon />}
          id="reminder"
          isLoading={appointmentReminderLoading}
          label="Reminder"
          onClick={getAppointmentReminder}
          variant="outlined"
          disabled={disableSummaryandReminder()}
        />

        <LoadingButton
          icon={<RefreshIcon />}
          id="refresh"
          isLoading={isRefreshing}
          label="Refresh"
          onClick={refresh}
          variant="outlined"
        />
      </div>

      {appointmentSummaryLoading || appointmentReminderLoading ? null : appointmentSummary || appointmentReminder ? (
        <div className="summary">
          <TextField
            label={appointmentSummary ? 'Summary' : appointmentReminder ? 'Reminder' : ''}
            disabled
            multiline
            value={appointmentSummary || appointmentReminder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                      color: '$primary-color',
                    }}
                    onClick={() => copyToClipboard(appointmentSummary || appointmentReminder)}
                  >
                    <ContentCopyIcon className="primary-color" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : null}

      <div className="content">
        {appointments.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Provider</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Time</TableCell>
                  <TableCell align="center">Duration</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Notes</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {appointments.map((appt) => (
                  <TableRow key={appt.id}>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'gray' : ''}>
                      {getProviderName(appt.providerId)}
                    </TableCell>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'gray' : ''}>
                      {formatDate(appt.date)}
                    </TableCell>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'gray' : ''}>
                      {formatTime(appt.startTime)}
                    </TableCell>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'gray' : ''}>
                      {appt.duration} mins
                    </TableCell>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'status gray' : 'status'}>
                      {appointmentStatusIcon(appt.status, appt.status)}
                    </TableCell>
                    <TableCell align="center" className={appt.status !== 'booked' ? 'gray' : ''}>
                      <Tooltip
                        title={
                          <div
                            style={{
                              whiteSpace: 'pre-line',
                              fontSize: '14px',
                            }}
                          >
                            {appt.notes}
                          </div>
                        }
                        placement="bottom"
                        arrow
                      >
                        <TextSnippetOutlinedIcon sx={{ color: appt.notes?.length ? 'black' : 'gray' }} />
                      </Tooltip>
                    </TableCell>
                    {appt.status === 'booked' ? (
                      <TableCell align="center">
                        <LoadingButton
                          classNames="cancel"
                          id={appt.id}
                          isLoading={cancelLoading === appt.id}
                          label="Cancel"
                          onClick={() => handleCancelModalOpen(appt)}
                          variant="outlined"
                        />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : campaignId ? (
          'No scheduled appointments for this campaign.'
        ) : (
          'Patient does not have any scheduled appointments.'
        )}

        <Button
          className="new-appointment"
          variant="contained"
          onClick={() => setShowNewApptOptions(!showNewApptOptions)}
        >
          New Appointment
        </Button>

        {showNewApptOptions ? (
          <div className="filters">
            <SpecialtyFilter
              getDepartments={getDepartments}
              specialtyId={specialtyId}
              setAppointmentSlots={setAppointmentSlots}
              setSpecialtyId={setSpecialtyId}
              setDepartmentFilter={setDepartmentFilter}
              setProviderFilter={setProviderFilter}
              setProvidersBySpecialtyAndDept={setProvidersBySpecialtyAndDept}
            />
            <DepartmentFilter
              getProviders={getProviders}
              resetProviderFilter={resetProviderFilter}
              selectedDepartments={departmentFilter}
              setDepartmentFilter={setDepartmentFilter}
              selectDepartment={selectDepartment}
              specialtyId={specialtyId}
            />
            <ProviderFilter
              providers={providersBySpecialtyAndDept}
              selectedProviders={providerFilter}
              selectProvider={selectProvider}
            />
            <AppointmentTypeFilter selectedType={apptType} setApptType={setApptType} />
            <div className="date-filters">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={dayjs(startDate)}
                  onChange={(date) => {
                    setStartDate(date.format('MM/DD/YYYY'));
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        ) : null}

        {appointmentSlotsLoading ? (
          <Loader />
        ) : (
          <div className="slots">
            {showNewApptOptions && appointmentSlots.length ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Provider</TableCell>
                      <TableCell align="center">Department</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Duration</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointmentSlots.map((slot) => (
                      <TableRow key={slot.appointmentId}>
                        <TableCell align="center">{getProviderName(slot.providerId)}</TableCell>
                        <TableCell align="center">
                          <div className="copy" onClick={() => copyDepartmentInfo(slot.departmentId)}>
                            {getDepartmentInfo(slot.departmentId)?.name}
                          </div>
                        </TableCell>
                        <TableCell align="center">{formatDate(slot.date)}</TableCell>
                        <TableCell align="center">{formatTime(slot.startTime)}</TableCell>
                        <TableCell align="center">{slot.duration} mins</TableCell>
                        <TableCell align="center">
                          <Button id={slot.appointmentId} onClick={handleModalOpen} variant="contained">
                            Book
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
