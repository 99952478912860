import { createSlice, current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { findIndex, reject } from 'lodash';

const initialState = {
  loading: true,
  showFacilitySelect: false,
  currentFacility: {},
  facilities: [],
  providers: [],
  specialties: [],
  departments: [],
  campaignActions: {},
  dashboardFilters: {
    query: '',
    providers: [],
    queue: 'TextOngoing',
    startDate: dayjs().subtract(7, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
    specialty: '',
    referralStatus: '',
  },
  uploadFileFilters: {
    search: '',
    queue: 'all',
  },
  uploadFilters: {
    startDate: dayjs().subtract(7, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
  },
  users: [],
  activeWidget: null,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentFacility: (state, action) => {
      state.currentFacility = action.payload;
    },
    setShowFacilitySelect: (state, action) => {
      state.showFacilitySelect = action.payload;
    },
    setFacilities: (state, action) => {
      state.facilities = action.payload;
    },
    setFilteredProviders: (state, action) => {
      const selectedProvider = action.payload;
      const currentProviders = current(state).dashboardFilters.providers;

      if (findIndex(currentProviders, ['id', selectedProvider.id]) > -1) {
        state.dashboardFilters.providers = reject(currentProviders, { id: selectedProvider.id });
      } else {
        state.dashboardFilters.providers.push(selectedProvider);
      }
    },
    setProviders: (state, action) => {
      state.providers = action.payload.providers;
      state.providersWithoutUnassigned = action.payload.providersWithoutUnassigned;
    },
    setCampaignActions: (state, action) => {
      state.campaignActions = action.payload;
    },
    setQuery: (state, action) => {
      state.dashboardFilters.query = action.payload;
    },
    setQueue: (state, action) => {
      const queue = action.payload;

      if (queue === 'ReadyForCall') {
        state.dashboardFilters.startDate = dayjs().subtract(30, 'days').format('MM/DD/YYYY');
        state.dashboardFilters.endDate = dayjs().format('MM/DD/YYYY');
      }

      state.dashboardFilters.queue = queue;
    },
    setSpecialties: (state, action) => {
      state.specialties = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    resetSystemState: (state) => {
      state = initialState;
    },
    resetFilters: (state) => {
      state.dashboardFilters = { ...initialState.dashboardFilters, queue: state.dashboardFilters.queue };
    },
    setStartDate: (state, action) => {
      state.dashboardFilters.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.dashboardFilters.endDate = action.payload;
    },
    setSpecialty: (state, action) => {
      state.dashboardFilters.specialty = action.payload;
    },
    setUploadFileFilters: (state, action) => {
      const filtersCopy = { ...state.uploadFileFilters };

      state.uploadFileFilters = { ...filtersCopy, ...action.payload };
    },
    setUploadFilters: (state, action) => {
      const filtersCopy = { ...state.uploadFilters };

      state.uploadFilters = { ...filtersCopy, ...action.payload };
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setReferralStatus: (state, action) => {
      state.dashboardFilters.referralStatus = action.payload;
    },
    setActiveWidget: (state, action) => {
      state.activeWidget = action.payload;
    },
  },
});

export const {
  setCurrentFacility,
  setFacilities,
  setFilteredProviders,
  setProviders,
  setCampaignActions,
  setQuery,
  setQueue,
  setShowFacilitySelect,
  setLoading,
  setStartDate,
  setEndDate,
  setUploadFileFilters,
  setUploadFilters,
  resetFilters,
  resetSystemState,
  setSpecialties,
  setDepartments,
  setUsers,
  setSpecialty,
  setReferralStatus,
  setActiveWidget,
} = systemSlice.actions;

export default systemSlice.reducer;

export const selectLoading = (state) => state.system.loading;
export const showFacilitySelect = (state) => state.system.showFacilitySelect;
export const selectCurrentFacility = (state) => state.system.currentFacility;
export const selectFacilities = (state) => state.system.facilities;
export const selectProviders = (state) => state.system.providers;
export const selectCampaignActions = (state) => state.system.campaignActions;
export const selectAllFilters = (state) => state.system.dashboardFilters;
export const selectQuery = (state) => state.system.dashboardFilters.query;
export const selectQueue = (state) => state.system.dashboardFilters.queue;
export const selectFilteredProviders = (state) => state.system.dashboardFilters.providers;
export const selectSpecialty = (state) => state.system.dashboardFilters.specialty;
export const selectStartDate = (state) => state.system.dashboardFilters.startDate;
export const selectEndDate = (state) => state.system.dashboardFilters.endDate;
export const selectReferralStatus = (state) => state.system.dashboardFilters.referralStatus;
export const selectUploadFileFilters = (state) => state.system.uploadFileFilters;
export const selectUploadFilters = (state) => state.system.uploadFilters;
export const selectSpecialties = (state) => state.system.specialties;
export const selectDepartments = (state) => state.system.departments;
export const selectAllUsers = (state) => state.system.users;
export const selectActiveWidget = (state) => state.system.activeWidget;
