import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectReferralStatus, setReferralStatus } from '../../state/systemSlice';
import { REFERRAL_STATUSES } from '../../utils/constants/campaign';

export default function ReferralStatusFilter() {
  const dispatch = useDispatch();
  const referralStatus = useSelector(selectReferralStatus);

  const handleClick = (status) => {
    if (status === referralStatus) {
      dispatch(setReferralStatus(''));
    } else {
      dispatch(setReferralStatus(status));
    }
  };

  return (
    <FormControl size="small" className="table-filter referral-status-filter">
      <InputLabel>Referral Status</InputLabel>
      <Select value={referralStatus} input={<OutlinedInput label="Referral Status" />}>
        {REFERRAL_STATUSES.map((status) => (
          <MenuItem key={status.value} value={status.value} onClick={() => handleClick(status.value)}>
            {status.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
