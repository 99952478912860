import './App.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, ScrollRestoration, useNavigate } from 'react-router-dom';

import ProtectedRoute from './auth/protected-route';
import FacilitySelect from './components/FacilitySelect/component';
import Loader from './components/Loader/Loader';
import Navbar from './components/Navbar/component';
import Widgets from './components/Widgets/component';
import HiroApi from './HiroApi';
import AuditHistory from './pages/AuditHistory/component';
import Campaign from './pages/Campaign/component';
import Dashboard from './pages/Dashboard/component';
import Login from './pages/Login/component';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import PatientProfile from './pages/PatientProfile/component';
import Settings from './pages/Settings/component';
import UploadDetail from './pages/UploadDetail/component';
import UploadList from './pages/UploadList/component';
import {
  selectCurrentFacility,
  selectLoading,
  setCurrentFacility,
  setLoading,
  setQueue,
  setShowFacilitySelect,
  showFacilitySelect,
} from './state/systemSlice';
import { selectUserType, setUserEmail, setUserName, setUserType } from './state/userSlice';
import { HIRO_FACILITY_TOKEN, USER_ROLES } from './utils/constants/system';
import { fetchCampaignActions, fetchProviders, fetchSpecialties } from './utils/helpers-api';

const { REACT_APP_AUTH0_DOMAIN } = process.env;

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
  const facility = useRef(null);
  const showFacilitySelectModal = useSelector(showFacilitySelect);
  const userType = useSelector(selectUserType);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const facilityInStorage = JSON.parse(localStorage.getItem(HIRO_FACILITY_TOKEN));
      const userRole = user[`${REACT_APP_AUTH0_DOMAIN}`].roles[0];

      dispatch(setUserName(user.name));
      dispatch(setUserEmail(user.email));
      dispatch(setCurrentFacility(facilityInStorage));
      facility.current = facilityInStorage;

      if (isEmpty(facilityInStorage)) {
        dispatch(setShowFacilitySelect(true));
      } else {
        HiroApi.facility = facilityInStorage;
        loadInitialData();
      }

      if (userRole) {
        dispatch(setUserType(userRole));

        if (userRole === USER_ROLES.COORDINATOR) {
          dispatch(setQueue('ReadyForCall'));
        }
      }
    }

    document.addEventListener('visibilitychange', checkForFacility);

    return () => {
      document.removeEventListener('visibilitychange', checkForFacility);
    };
  }, [user]);

  const checkForFacility = () => {
    const facilityInStorage = JSON.parse(localStorage.getItem(HIRO_FACILITY_TOKEN));

    if (isEmpty(facilityInStorage) && facility.current.id !== facilityInStorage.id) {
      dispatch(setShowFacilitySelect(true));
      dispatch(setCurrentFacility({}));
      navigate('/');
    }
  };

  const loadInitialData = async () => {
    const token = await getAccessTokenSilently();

    await fetchProviders(false, token);
    await fetchSpecialties(token);
    await fetchCampaignActions(token);
    dispatch(setLoading(false));
  };

  return (
    <div className="App">
      <Navbar />
      {isAuthenticated && loading ? (
        <Loader centered />
      ) : (
        <Routes>
          <Route exact path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
          <Route exact path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
          <Route exact path="/patients/:id" element={<ProtectedRoute component={PatientProfile} />} />
          <Route exact path="/campaigns/:id" element={<ProtectedRoute component={Campaign} />} />
          <Route exact path="/patients/upload" element={<ProtectedRoute component={UploadList} />} />
          <Route exact path="/patients/upload/:id" element={<ProtectedRoute component={UploadDetail} />} />
          {userType === USER_ROLES.ADMIN ? (
            <Route exact path="/settings" element={<ProtectedRoute component={Settings} />} />
          ) : null}
          {userType === USER_ROLES.ADMIN ? (
            <Route exact path="/audit" element={<ProtectedRoute component={AuditHistory} />} />
          ) : null}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
      <ScrollRestoration />

      {showFacilitySelectModal ? <FacilitySelect facilityRef={facility} /> : null}

      {isAuthenticated && !loading ? <Widgets /> : null}
    </div>
  );
}
