import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HiroApi from '../../HiroApi';
import {
  selectCurrentFacility,
  selectFacilities,
  setCurrentFacility,
  setFacilities,
  setLoading,
  setShowFacilitySelect,
} from '../../state/systemSlice';
import { HIRO_FACILITY_TOKEN } from '../../utils/constants/system';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function FacilitySelect(props) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const currentFacility = useSelector(selectCurrentFacility);
  const facilities = useSelector(selectFacilities);
  const [selectedFacility, setSelectedFacility] = useState({});
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(facilities)) {
      getFacilities();
    }
  }, [facilities]);

  const getFacilities = async () => {
    try {
      const token = await getAccessTokenSilently();
      const facilities = await HiroApi.getFacilities(token);

      dispatch(setFacilities(facilities));
    } catch (error) {
      console.log(error);
    }
  };

  const selectFacility = (event) => {
    const selectedFacility = facilities.find((facility) => facility.id === event.target.value);

    setSelectedFacility(selectedFacility);
  };

  const submit = async () => {
    localStorage.setItem(HIRO_FACILITY_TOKEN, JSON.stringify(selectedFacility));
    await navigate('/dashboard');
    window.location.reload();
  };

  const cancel = () => {
    dispatch(setShowFacilitySelect(false));
    dispatch(setLoading(false));
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={modalStyle} className="facility-select">
          <div className="select">
            Select Facility:
            <FormControl>
              <Select value={selectedFacility.id || ''} onChange={selectFacility}>
                {facilities.map((facility) => (
                  <MenuItem key={facility.id} value={facility.id}>
                    {facility.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="submit">
            <Button variant="contained" onClick={submit} disabled={isEmpty(selectedFacility)}>
              Continue
            </Button>
            {isEmpty(currentFacility) ? null : (
              <Button variant="contained" onClick={cancel}>
                Cancel
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
