import './style.scss';

import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="page-not-found">
      <h1>Page Not Found</h1>

      <Button variant="contained" color="primary" onClick={handleBackToDashboard}>
        Back to Dashboard
      </Button>
    </div>
  );
}
