export const USER_ROLES = {
  ADMIN: 'kouper',
  COORDINATOR: 'provider',
};

export const MAX_QUERY_LIMIT = 1024;

export const DEBOUNCE_TIME = 400;

export const DEBOUNCE_TIME_LONG = 2000;

export const HIRO_FACILITY_TOKEN = `hiro-${window.location.origin}`;

export const UNASSIGNED_PROVIDER_ID = '00000000-0000-0000-0000-000000000000';

export const CONVERSATION_ACTOR = {
  AI: 'ai',
  COORDINATOR: 'coordinator',
  SYSTEM: 'system',
  PATIENT: 'patient',
  FUNCTION: 'function',
};

export const MESSAGE_STATUS = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  READ: 'read',
  FAILED: 'failed',
  UNDELIVERED: 'undelivered',
};

export const CONSENT_STATUS = {
  IN: 'in',
  OUT: 'out',
  PENDING: 'pending',
};

export const INCLUSION_CATEGORIES = [
  {
    label: 'PCP',
    column: 'pcp',
  },
];

export const FACILITIES = {
  FRYE: 'frye',
  PARIS: 'paris',
  STILLWATER: 'still',
  WILSON: 'wlson',
};
