import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Device } from '@twilio/voice-sdk';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HiroApi from '../../HiroApi';
import { fetchContactAttempts } from '../../state/campaignSlice';
import { selectCurrentFacility, setActiveWidget } from '../../state/systemSlice';
import {
  selectCurrentCampaignId,
  selectPhoneNumber,
  selectStatus,
  setPhoneNumber,
  setStatus,
} from '../../state/twilioSlice';
import { selectUserEmail } from '../../state/userSlice';
import { CALL_EVENT, DEVICE_STATUS } from '../../utils/constants/twilio';
import { phoneFormat } from '../../utils/helpers';

export default function Twilio({ isOpen }) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const callRef = useRef(null);
  const deviceRef = useRef(null);
  const initializedRef = useRef(false);
  const campaignId = useSelector(selectCurrentCampaignId);
  const facility = useSelector(selectCurrentFacility);
  const phoneNumber = useSelector(selectPhoneNumber);
  const status = useSelector(selectStatus);
  const userEmail = useSelector(selectUserEmail);
  const [callSid, setCallSid] = useState('');
  const [foundCampaign, setFoundCampaign] = useState('');
  const [recordingSid, setRecordingSid] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (!initializedRef.current && !callRef.current) {
      initializeDevice();
      initializedRef.current = true;
    }

    return () => {
      if (callRef.current) {
        callRef.current.disconnect();
        callRef.current = null;
      }

      if (deviceRef.current) {
        deviceRef.current.destroy();
        deviceRef.current = null;
      }
      initializedRef.current = false;
    };
  }, [location.pathname]);

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      const twilioToken = await HiroApi.getTwilioToken(userEmail, token);

      dispatch(setStatus('Got valid token'));

      return twilioToken.token;
    } catch (error) {
      dispatch(setStatus('Get token failed'));
      console.log(error);
      return null;
    }
  };

  const refreshToken = async (twilioDevice) => {
    try {
      dispatch(setStatus(DEVICE_STATUS.TOKEN_WILL_EXPIRE.label));
      const newToken = await getToken();

      if (!newToken) {
        dispatch(setStatus('Token refresh failed'));
      }

      await twilioDevice.updateToken(newToken);
    } catch (error) {
      dispatch(setStatus('Token refresh failed'));
    }
  };

  const initializeDevice = async () => {
    if (callRef.current || deviceRef.current) return deviceRef.current;

    try {
      const token = await getToken();
      const twilioDevice = new Device(token, {
        closeProtection: true,
        enableImprovedSignalingErrorPrecision: true,
        logLevel: 1,
      });

      twilioDevice.on(DEVICE_STATUS.ERROR.value, (error) => {
        dispatch(setStatus(`Device Error: ${error.message}`));
      });

      twilioDevice.on(DEVICE_STATUS.REGISTERING.value, () => dispatch(setStatus(DEVICE_STATUS.REGISTERING.label)));

      twilioDevice.on(DEVICE_STATUS.REGISTERED.value, () => dispatch(setStatus(DEVICE_STATUS.REGISTERED.label)));

      twilioDevice.on(DEVICE_STATUS.UNREGISTERED.value, () => dispatch(setStatus(DEVICE_STATUS.UNREGISTERED.label)));

      twilioDevice.on(DEVICE_STATUS.DESTROYED.value, () => dispatch(setStatus(DEVICE_STATUS.DESTROYED.label)));

      twilioDevice.on(DEVICE_STATUS.TOKEN_WILL_EXPIRE.value, () => refreshToken(twilioDevice));

      twilioDevice.on(DEVICE_STATUS.INCOMING.value, (connection) => {
        const callSid = connection.parameters.CallSid;
        const fromNumber = connection.parameters.From;
        const campaignId = connection.customParameters.get('campaignId');

        callRef.current = connection;
        dispatch(setActiveWidget('twilio'));
        setCallSid(callSid);
        setFoundCampaign(campaignId);
        dispatch(setPhoneNumber(fromNumber.replace(/^\+1/, '')));
        dispatch(setStatus(DEVICE_STATUS.INCOMING.label));
        attachCallEventListeners(connection);
      });

      await twilioDevice.register();
      deviceRef.current = twilioDevice;

      return twilioDevice;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const makeCall = async () => {
    let twilioDevice = deviceRef.current;

    if (!twilioDevice) {
      twilioDevice = await initializeDevice();
    }

    try {
      const metadata = {
        facilityId: facility.id,
        campaignId,
        coordinatorId: userEmail,
      };

      const outgoingCall = await twilioDevice.connect({
        params: { To: `+1${phoneNumber}`, ...metadata },
      });

      callRef.current = outgoingCall;
      dispatch(setStatus('Calling...'));
      attachCallEventListeners(outgoingCall);
    } catch (error) {
      console.error('Error during call setup:', error);
      dispatch(setStatus('Error: Unable to make the call'));
    }
  };

  const attachCallEventListeners = (connection) => {
    connection.on(CALL_EVENT.ACCEPT.value, () => {
      const callSid = callRef.current?.parameters?.CallSid;

      dispatch(setStatus(CALL_EVENT.ACCEPT.label));
      setIsRecording(true);
      setCallSid(callSid);
    });

    connection.on(CALL_EVENT.DISCONNECT.value, async () => {
      const token = await getAccessTokenSilently();

      dispatch(setStatus(CALL_EVENT.DISCONNECT.label));
      callRef.current = null;
      dispatch(fetchContactAttempts(campaignId, token));
      reset();
    });

    connection.on(CALL_EVENT.ERROR.value, (error) => {
      dispatch(setStatus(`${CALL_EVENT.ERROR.label}: ${error.message}`));
      console.error('Call error:', error);
    });

    connection.on(CALL_EVENT.CANCEL.value, () => {
      dispatch(setStatus('Call canceled'));
      callRef.current = null;
    });

    connection.on(CALL_EVENT.RECONNECTING.value, () => dispatch(setStatus(CALL_EVENT.RECONNECTING.label)));

    connection.on(CALL_EVENT.RECONNECTED.value, () => dispatch(setStatus(CALL_EVENT.RECONNECTED.label)));

    connection.on(CALL_EVENT.RINGING.value, () => dispatch(setStatus(CALL_EVENT.RINGING.label)));

    connection.on(CALL_EVENT.REJECT.value, () => {
      dispatch(setStatus(CALL_EVENT.REJECT.label));
      callRef.current = null;
      reset();
    });
  };

  const acceptCall = () => {
    if (callRef.current) {
      callRef.current.accept();
      dispatch(setStatus(CALL_EVENT.ACCEPT.label));
    }
  };

  const rejectCall = async () => {
    if (callRef.current) {
      callRef.current.reject();
      dispatch(setStatus(CALL_EVENT.REJECT.label));
      const token = await getAccessTokenSilently();
      await HiroApi.rejectCall(callSid, token);
      reset();
    }
  };

  const endCall = async () => {
    const token = await getAccessTokenSilently();

    await HiroApi.endCall(callSid, token);
    callRef.current = null;
    reset();
  };

  const handlePhoneNumberChange = (event) => {
    dispatch(setPhoneNumber(event.target.value));
  };

  const showCallLoading = () => {
    if (status === CALL_EVENT.RINGING.label || status === CALL_EVENT.RECONNECTING.label) {
      return true;
    }

    return false;
  };

  const getRecordingSid = async (callSid) => {
    try {
      const token = await getAccessTokenSilently();

      return await HiroApi.getRecordingSid(callSid, token);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const toggleRecording = async () => {
    try {
      const token = await getAccessTokenSilently();
      const action = isRecording ? 'stop' : 'start';
      const payload = { action, callSid };

      if (isRecording) {
        payload.recordingSid = await getRecordingSid(callSid);
      }

      await HiroApi.toggleRecording(payload, token);
      setIsRecording(!isRecording);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMute = () => {
    if (callRef.current) {
      callRef.current.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const reset = () => {
    callRef.current = null;

    dispatch(setPhoneNumber(''));
    dispatch(setStatus(''));

    setCallSid('');
    setRecordingSid('');
    setIsMuted(false);
    setIsRecording(false);
  };

  const renderCallButton = () => {
    if (callRef.current && status === DEVICE_STATUS.INCOMING.label) {
      return (
        <div className="options">
          <Button variant="outlined" onClick={acceptCall}>
            Accept
          </Button>
          <Button variant="outlined" className="reject" onClick={rejectCall}>
            Reject
          </Button>
        </div>
      );
    }

    if (callRef.current && status === CALL_EVENT.ACCEPT.label) {
      return (
        <Button variant="outlined" onClick={endCall}>
          End Call
        </Button>
      );
    }

    return (
      <Button variant="outlined" onClick={makeCall} disabled={isEmpty(phoneNumber) || isEmpty(campaignId)}>
        Call
      </Button>
    );
  };

  return isOpen ? (
    <Paper className="twilio" elevation={3}>
      <TextField disabled label="Status" size="small" value={status} variant="standard" />

      <TextField
        label={phoneNumber ? 'Phone Number' : ''}
        onChange={handlePhoneNumberChange}
        placeholder="(123) 456-7890"
        size="small"
        value={phoneFormat(phoneNumber)}
        variant="standard"
      />

      {renderCallButton()}

      {showCallLoading() ? <LinearProgress className="progress" /> : null}

      <div className="actions">
        <Tooltip title="Mute" placement="bottom" arrow>
          <IconButton onClick={toggleMute}>
            {isMuted ? <MicOffIcon className="red" /> : <MicIcon className="gray" />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Record" placement="bottom" arrow>
          <IconButton onClick={toggleRecording}>
            {isRecording ? <RadioButtonCheckedIcon className="red" /> : <RadioButtonCheckedIcon className="gray" />}
          </IconButton>
        </Tooltip>

        {foundCampaign ? (
          <Tooltip title="Go to campaign" placement="bottom" arrow>
            <IconButton onClick={() => navigate(`/campaigns/${foundCampaign}`)}>
              <CampaignOutlinedIcon className="green border" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Paper>
  ) : null;
}
