import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: '',
  status: '',
  currentCampaignId: '',
};

export const twilioSlice = createSlice({
  name: 'twilioSlice',
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCurrentCampaignId: (state, action) => {
      state.currentCampaignId = action.payload;
    },
  },
});

export const { setPhoneNumber, setStatus, setCurrentCampaignId } = twilioSlice.actions;

export const selectPhoneNumber = (state) => state.twilio.phoneNumber;
export const selectStatus = (state) => state.twilio.status;
export const selectCurrentCampaignId = (state) => state.twilio.currentCampaignId;

export default twilioSlice.reducer;
