import './style.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';

export default function FunctionMessage(props) {
  const { functionMessages } = props;

  const formatJson = (json) => {
    return <code>{JSON.stringify(JSON.parse(json.replace(/'/g, '"')), null, 2)}</code>;
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Function Message</AccordionSummary>
      <AccordionDetails>
        {functionMessages.map((message) => {
          return (
            <div className="body" key={message.id}>
              <h4>{message.functionName}</h4>
              {formatJson(message.body)}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
