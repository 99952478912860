import { isEmpty } from 'lodash';

import HiroApi from '../HiroApi';
import store from '../state/store';
import { setCampaignActions, setDepartments, setProviders, setSpecialties } from '../state/systemSlice';

export const fetchProviders = async (forceFetch, token) => {
  const state = store.getState();
  const providersInStore = state.system.providers;

  if (isEmpty(providersInStore) || forceFetch) {
    try {
      const providers = await HiroApi.getProviders(null, null, token);
      const providersWithoutUnassigned = providers.filter((p) => p.id !== 1);

      store.dispatch(setProviders({ providers, providersWithoutUnassigned }));
    } catch (error) {
      console.log(error);
    }
  }
};

export const fetchSpecialties = async (token) => {
  const state = store.getState();
  const specialtiesInStore = state.system.specialties;

  if (isEmpty(specialtiesInStore)) {
    try {
      const specialties = await HiroApi.getSpecialties(token);

      store.dispatch(setSpecialties(specialties));
    } catch (error) {
      console.log(error);
    }
  }
};

export const fetchDepartments = async (specialtyId, token) => {
  try {
    const departments = await HiroApi.getDepartments(specialtyId, token);

    store.dispatch(setDepartments(departments));
  } catch (error) {
    console.log(error);
  }
};

export const fetchCampaignActions = async (token) => {
  try {
    const actions = await HiroApi.getActionOptions(token);

    store.dispatch(setCampaignActions(actions));
  } catch (error) {
    console.log(error);
  }
};

export const translate = async (payload, token) => {
  try {
    return await HiroApi.translateMessage(payload, token);
  } catch (error) {
    return error;
  }
};
