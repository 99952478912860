import { useAuth0 } from '@auth0/auth0-react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import HiroApi from '../../HiroApi';
import { selectCampaignActions } from '../../state/systemSlice';

export default function CampaignActions({ campaignId }) {
  const { getAccessTokenSilently } = useAuth0();
  const optionsRef = useRef(null);
  const campaignActions = useSelector(selectCampaignActions);
  const [actions, setActions] = useState([]);
  const [showAddActions, setShowAddActions] = useState(false);

  useEffect(() => {
    if (campaignId) {
      getCampaignActions();
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [campaignId]);

  const getCampaignActions = async () => {
    try {
      const token = await getAccessTokenSilently();
      const fetchedActions = await HiroApi.getCampaignActions(campaignId, token);

      setActions(fetchedActions);
    } catch (error) {
      console.log(error);
    }
  };

  const createCampaignAction = async (action) => {
    try {
      const token = await getAccessTokenSilently();
      const newAction = await HiroApi.createCampaignAction(campaignId, { action }, token);

      setActions((prevActions) => [...prevActions, newAction]);
    } catch (error) {
      console.log(error);
    }
  };

  const resolveCampaignAction = async (actionId) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.resolveCampaignAction(campaignId, actionId, token);
      setActions((prevActions) => prevActions.filter((action) => action.id !== actionId));
    } catch (error) {
      console.log(error);
    }
  };

  const getAvailableActions = (currentActions) => {
    const currentActionValues = currentActions.map((a) => a.action);

    return Object.entries(campaignActions)
      .filter(([key]) => !currentActionValues.includes(key))
      .map(([key, label]) => ({
        value: key,
        label,
      }));
  };

  const getActionLabel = (action) => {
    return campaignActions[action] || action;
  };

  const handleAddAction = async (action) => {
    await createCampaignAction(action);
    setShowAddActions(false);
  };

  const handleDeleteAction = async (actionId) => {
    await resolveCampaignAction(actionId);
  };

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowAddActions(false);
    }
  };

  return (
    <div className="campaign-actions">
      <Chip
        size="small"
        variant="outlined"
        label="Action"
        className={`action-chip ${showAddActions ? 'active' : ''}`}
        onClick={() => setShowAddActions(!showAddActions)}
        icon={<AddOutlinedIcon />}
      />

      <div className="current-actions">
        {actions.map((action) => (
          <Chip
            size="small"
            variant="outlined"
            key={action.id}
            label={getActionLabel(action.action)}
            onClick={() => handleDeleteAction(action.id)}
            icon={<CloseOutlinedIcon />}
          />
        ))}
      </div>

      <Paper ref={optionsRef} elevation={1} className={`add-actions ${showAddActions ? 'open' : ''}`}>
        {getAvailableActions(actions).map((action) => (
          <Chip
            size="small"
            variant="outlined"
            key={action.value}
            label={action.label}
            onClick={() => handleAddAction(action.value)}
          />
        ))}
      </Paper>
    </div>
  );
}

CampaignActions.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
